<!DOCTYPE HTML>
<html>
<body>
    <header class="header">
      <h1>Find My Parking Number</h1>
    </header>
    <h1 class="tosHeader">Terms of Service:</h1>
    <p class="tosText">
        All Rights Reserved 2021 Nazar Viznytsya
        <br><br>

        These conditions of use apply to the websites myparkingnumber.web.app, myparkingnumber.ca, and myparkingnumber.firebase.app.
        <br><br>
        While the information on this website is public to all, you may not reproduce, copy, or mirror any portion of this website for monetary gain. 
        <br><br>
        You may:
    </p>
    <ul>
        <li class="tosText">Reference this website in your own documents. </li>
        <li class="tosText">Print copies for personal use.</li>
        <li class="tosText">Use the information found on this website for personal and/or commercial use.</li>
    </ul>
    <p class="tosText">
        Disclaimer of liability:    
    </p>
    <ol>
        <li class="tosText">Nazar Viznytsya does make any representation as to the quality and accuracy of the content. All information on this website has been sourced from several different third parties and is subject to change without notice.</li>
        <li class="tosText">By using this website you release Nazar Viznytsya from any and all liability, claims, demands, either in law or equity which may arise from incorrect or inaccurate data displayed on the websites.</li>
        <li class="tosText">You acknowledge that map data may be outdated and/or inaccurate. By using the map you accept the fact that the map may be inaccurate and waive all liability from Nazar Viznytsya as a result of damages caused by incorrect map data.</li>
        <li class="tosText">By using directions, you acknowledge that the directions may not include clear paths. As such by using directions you acknowledge the risks and waive all liability from Nazar Viznytsya as a result of damages caused by directions.</li>
        <li class="tosText">You acknowledge information regarding the parking stations, including but not limited to cost, parking stations, and hours may not be accurate as to the current state of the parking stations. By using the data provided on the parking stations you acknowledge the risks and waive all liability from Nazar Viznytsya as a result of damages caused by incorrect data.</li>
        <li class="tosText">You acknowledge that any external links will open the website to which they are linking in a new browser tab. Nazar Viznytsya does not make any claim to the accuracy, confidentiality, and integrity of any third-party websites. By using external websites you waive all liability from Nazar Viznytsya as a result of damages caused by external websites.</li>
    </ol>
    <div class="centeringDiv">
        <a routerLink="/">Home</a>
    </div>
</body>
</html>